import * as http from './http';
import ApiService from './service';

const serviceEndpoint = 'user/v1';

export default class RegistrationService extends ApiService {
  public async updateDetails(data: any) {
    const url = `${this.apiDomain}/${serviceEndpoint}/update-profile`;
    return http.put(url, data, this.token);
  }

  public async getCourses(preApplyPromoCode: boolean) {
    const url = `${this.apiDomain}/${serviceEndpoint}/student/courses?preApplyPromotion=${preApplyPromoCode}`;
    return http.get(url, this.token);
  }

  public async chooseCourse(data: any) {
    const url = `${this.apiDomain}/${serviceEndpoint}/student/course`;
    return http.post(url, data, this.token);
  }

  public async getCourse() {
    const url = `${this.apiDomain}/${serviceEndpoint}/student/course`;
    return http.get(url, this.token);
  }

  public async initiatePayment(data: any) {
    const url = `${this.apiDomain}/${serviceEndpoint}/student/course/payment`;
    return http.post(url, data, this.token);
  }

  public async getPromotions(data: any) {
    const url = `${this.apiDomain}/${serviceEndpoint}/promotions`;
    return http.get(url, this.token);
  }

  public async applyPromotion(data: any) {
    const url = `${this.apiDomain}/${serviceEndpoint}/apply-promotion`;
    return http.post(url, data, this.token);
  }

  public async applyReferral(data: any,) {
    const url = `${this.apiDomain}/user/v1/apply-referral`;
    return http.post(url, data, this.token);
  }
  public async removeReferral(data: any) {
    const url = `${this.apiDomain}/user/v1/remove-referral`;
    return http.post(url, data, this.token);
  }
  public async cancellReferral(data: any) {
    const url = `${this.apiDomain}/user/v1/cancell-referral`;
    return http.post(url, data, this.token);
  }


}
